import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout/layout";

import Helmet from "react-helmet";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";
import RelatedViewsBlock from "../components/browser/related-views";
import ConnectedNarrativesBlock from "../components/browser/connected-narratives";
import ImageGrid from "../components/images/grid";


class Artist extends React.Component {
  constructor(props) {
    super(props);


    this.data = props.data;

    this.otherNamesList = this.otherNamesList.bind(this);
    this.performancesLink = this.performancesLink.bind(this);
    this.performanceYearsLinks = this.performanceYearsLinks.bind(this);
    this.imagesLink = this.imagesLink.bind(this);
    this.imageYearsLinks = this.imageYearsLinks.bind(this);
  }

  otherNamesList() {
    if (this.data.artist.other_names.length === 1) {
      return (<span>‘{this.data.artist.other_names[0]}’</span>);
    }

    if (this.data.artist.other_names.length === 2) {
      return (<span>‘{this.data.artist.other_names[0]}’ or ‘{this.data.artist.other_names[1]}’</span>);
    }

    return (
      <span>
        {this.data.artist.other_names.map((name, idx, self) => (
          <span>
            {(idx < self.length - 1) ?
              (<span>‘{name}’, </span>)
              :
              (<span> or ‘{name}’</span>)
            }
          </span>
        ))}
      </span>
    );
  }

  performancesLink() {
    const edgesCount = this.data.artistPerformances.edges.length;
    const linkLabel = `${edgesCount} ${(edgesCount === 1) ? 'performance' : 'performances'}`;

    return (<Link to={`/performances/artist/${this.data.artist.reference}`}>{linkLabel}</Link>);
  }

  imagesLink() {
    const edgesCount = this.data.artistImages.edges.length;
    const linkLabel = `${edgesCount} ${(edgesCount === 1) ? 'image' : 'images'}`;

    return (<Link to={`/images/artist/${this.data.artist.reference}`}>{linkLabel}</Link>);
  }

  performanceYearsLinks() {
    const allYears = this.data.artistPerformances.edges.reduce((result, { node }) => {
      node.dates.forEach(date => result.push(date.substr(0, 4)));

      return result;
    }, []);
    const uniqueYears = allYears.filter((year, idx, self) => self.indexOf(year) === idx);

    if (uniqueYears.length === 1) {
      return (<Link to={`/performances/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>);
    }

    if (uniqueYears.length === 2) {
      return (
        <span>
          <Link to={`/performances/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>
          <span> and </span>
          <Link to={`/performances/years/${uniqueYears[1]}`}>{uniqueYears[1]}</Link>
        </span>
      );
    }

    return (
      <span>
        {uniqueYears.map((year, idx, self) => (
          <span key={idx}>
            {(idx < self.length - 1) ?
              (<span><Link to={`/performances/years/${year}`}>{year}</Link>, </span>)
              :
              (<span>and <Link to={`/performances/years/${year}`}>{year}</Link></span>)
            }
          </span>
        ))}
      </span>
    );
  }

  imageYearsLinks() {
    const allYears = this.data.artistImages.edges.map(({ node }) => node.year );

    const uniqueYears = allYears.filter((year, idx, self) => {
      return (year !== null) && (self.indexOf(year) === idx);
    });

    if (uniqueYears.length === 1) {
      return (<Link to={`/images/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>);
    }

    if (uniqueYears.length === 2) {
      return (
        <span>
          <Link to={`/images/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>
          <span> and </span>
          <Link to={`/images/years/${uniqueYears[1]}`}>{uniqueYears[1]}</Link>
        </span>
      );
    }

    return (
      <span>
        {uniqueYears.map((year, idx, self) => (
          <span>
            {(idx < self.length - 1) ?
              (<span><Link to={`/images/years/${year}`}>{year}</Link>, </span>)
              :
              (<span>and <Link to={`/images/years/${year}`}>{year}</Link></span>)
            }
          </span>
        ))}
      </span>
    );
  }
  
  render() {
    const data = this.data;

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{`${data.artist.canonical_name} – Artist`}</title>
          </Helmet>
          
          <PageSection background="dark-accent">
            <h1 className="page-title">
              {data.artist.canonical_name}
            </h1>
          </PageSection>

          {(data.artistPerformances && data.artistPerformances.edges.length > 0) ? (
            <PageSection background="gray" wrapper="wrapper" topPadding="md" bottomPadding="md">
              <p className="long-display-block">
                <b>{data.artist.canonical_name}</b> appears in {this.performancesLink()} from {this.performanceYearsLinks()}.
              </p>
            </PageSection>
          ) : null}

          {(data.artist.other_names.length > 0) ? (
            <PageSection background="gray" wrapper="wrapper" bottomPadding="md">
              <p className="long-display-block">
                <b>{data.artist.canonical_name}</b> sometimes appeared as {this.otherNamesList()}.
              </p>
            </PageSection>
          ) : null}

          {(data.artistImages && data.artistImages.edges.length > 0) ? (
            <PageSection background="gray" wrapper="wrapper" bottomPadding="md">
              <p className="long-display-block">
                There {(data.artistImages.edges.length === 1) ? (<span>is</span>) : (<span>are</span>)} {this.imagesLink()} of <b>{data.artist.canonical_name}</b> from {this.imageYearsLinks()}.
              </p>
            </PageSection>
          ) : null}
  
          <PageSection topPadding="md">

            {(data.connectedNarratives) ?  (
              <div className="entity-page__block">
                <h2>Read more about {data.artist.canonical_name} in…</h2>
                <ConnectedNarrativesBlock
                    exhibits={data.allExhibitsJson.edges}
                    images={data.allImagesJson.edges}
                    narratives={data.connectedNarratives.edges} />
              </div>
            ) : null}

            {(data.relatedArtists) ? (
              <div className="entity-page__block">
                <RelatedViewsBlock views={data.relatedArtists.edges}
                                  path="/artists/"
                                  label="Related Artists" />
              </div>
            ) : null}

            {(data.artistImages && data.artistImages.length > 0) ? (
              <div className="entity-page__block">
                <h2>Images of {data.artist.canonical_name}</h2>
                <ImageGrid images={data.artistImages.edges}
                          narratives={data.allNarrativesJson.edges}
                          artists={data.allArtistsJson.edges}
                          venues={data.allVenuesJson.edges} />
              </div>
                        
            ) : null}
            
          </PageSection>

          <PageSection
            topPadding="lg"
            bottomPadding="md"
          >
            <div className="big-button big-button__artists">
              <Link to="/artists">
                <span className="label">Browse Artists</span>
              </Link>
            </div>  
          </PageSection>

        </PageContainer>
      </Layout>
    );
  }
}

export default Artist;

export const query = graphql`
  query ArtistQuery($slug: String!) {
    artistPerformances: allPerformancesJson(
      filter: {artists: {eq: $slug}, reviewed: {eq: true}},
      sort: {fields: [dates], order: ASC}) {
      edges {
        node {
          dates
          artists
          venue
        }
      }
    },
    artistImages: allImagesJson(
      filter: {artists: {eq: $slug}}) {
      edges {
        node {
          reference
          fullPath
          thumbPath
          alt
          caption
          credit
          narratives
          artists
          venues
          year
        }
      }
    },
    artist: artistsJson(reference: { eq: $slug }) {
      reference
      canonical_name
      other_names
    },
    relatedArtists: allArtistsJson(filter: {related: {eq: $slug}}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    connectedNarratives: allNarrativesJson(filter: {artists: {eq: $slug}}) {
      edges {
        node {
          featuredImage
          exhibit
          reference
          title
          blurb
        }
      }
    },
    allExhibitsJson {
      edges {
        node {
          reference
          long_title
        }
      }
    },
    allNarrativesJson {
      edges {
        node {
          reference
          title
        }
      }
    },
    allImagesJson {
      edges {
        node {
          reference
          alt
          thumbPath
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`;